<template>
  <div>
    <el-table
      :data="cEstimateDetailsTableData"
      row-key="id"
      border
      :summary-method="getSummaries"
      show-summary
      v-loading="loading"
      size="mini"
      :header-cell-style="{ 'text-align': 'center' }"
      style="width: 100%; margin-top: 20px"
      :row-style="TableRowStyle"
    >
      <!-- :cell-style="{ 'text-align': 'right' }" -->
      <template slot="empty">
        <slot name="empty"></slot>
      </template>
      <el-table-column type="index" label="No." width="50" align="right">
      </el-table-column>
      <el-table-column prop="product_name" label="商品名">
        <template slot-scope="scope">
          <el-autocomplete
            v-if="scope.row.isEdit"
            popper-class="my-autocomplete"
            v-model="scope.row.product_name"
            :fetch-suggestions="querySearch"
            placeholder="商品"
            style="width: 100%"
            @select="handleSelect"
          >
            <i
              class="el-icon-edit el-input__icon"
              slot="suffix"
              @click="handleIconClick"
            ></i>
            <template slot-scope="{ item }">
              <div class="name">{{ (item.value = item.product_name) }}</div>
              <span class="category" v-if="item.category2"
                >カテゴリー:{{ item.category2 }} / {{ item.category1 }} 単価:{{
                  item.price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,")
                }}</span
              >
              <span class="category" v-else
                >カテゴリー:{{ item.category1 }} 単価:{{
                  item.price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,")
                }}</span
              >
            </template>
          </el-autocomplete>
          <span v-else>{{ scope.row.product_name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="price"
        label="単価"
        :formatter="stateFormat"
        width="100"
        align="right"
      >
        <template slot-scope="scope">
          <el-input v-if="scope.row.isEdit" v-model="price"></el-input>
          <span v-else>{{
            scope.row.price.toString().replace(/(\d)(?=(\d{3})+$)/g, "$1,")
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="quantity" label="数量" width="100" align="right">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.isEdit"
            v-model="scope.row.quantity"
          ></el-input>
          <span v-else>{{ scope.row.quantity }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="sub_total"
        label="金額"
        :formatter="stateFormat"
        width="150"
        align="right"
      >
      </el-table-column>
      <el-table-column
        prop="tax"
        label="消費税"
        :formatter="stateFormat"
        width="150"
        align="right"
      >
      </el-table-column>
      <el-table-column prop="remarks" label="備考" width="200">
        <template slot-scope="scope">
          <el-input
            v-if="scope.row.isEdit"
            v-model="scope.row.remarks"
            maxlength="13"
          ></el-input>
          <span v-else>{{ scope.row.remarks }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            v-if="!scope.row.isEdit"
            :disabled="editing"
            @click="edit(scope.row, scope.$index)"
            >編集
          </el-button>
          <el-button
            type="text"
            size="small"
            v-if="scope.row.isEdit"
            @click="save(scope.row, scope.$index)"
            >保存
          </el-button>
          <el-button
            type="text"
            size="small"
            v-if="scope.row.isEdit"
            @click="cancel(scope.row, scope.$index)"
            >キャンセル
          </el-button>
          <el-button
            type="text"
            size="small"
            v-if="scope.row.isEdit"
            :disabled="scope.row.isAdd"
            @click="handleDelete(scope.$index, scope.row)"
            >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-button @click="add()" style="width: 100%" :disabled="this.editing" plain
      >追加
    </el-button>
  </div>
</template>

<script>
import {
  deleteEstimateDetail,
  postEstimateDetailsData,
  putEstimateDetailsData
} from "@/api/estimates";
import {
  deleteInvoiceDetail,
  putInvoiceDetailsData,
  postInvoiceDetailsData
} from "@/api/invoice";
import { NewProjectcode } from "@/utils/code_util";
import { Message } from "element-ui";

export default {
  name: "EstimateDetailsTable",
  props: {
    cEstimateDetailsTableData: {
      type: Array,
      default() {
        return [];
      }
    },
    cProductsDataAll: {
      type: Array,
      default() {
        return [];
      }
    },
    cEstimateCode: {
      // type: String,
      // default() {
      //   return "";
      // }
    },
    cInvoiceCode: {
      type: String,
      default() {
        return "";
      }
    },
    cMainFlag: {
      type: Boolean,
      default() {
        return false;
      }
    },
    cInvoiceForm: {
      type: Boolean,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      tableData: [],
      price: "",
      editing: false,
      loading: false
    };
  },
  mounted() {
    // 阻止默认行为
    document.body.onEnd = function(event) {
      event.preventDefault();
      event.stopPropagation();
    };
    // this.initialDrop();
    // this.runningDrop();
  },
  methods: {
    sub_total(row) {
      return row.price * row.quantity;
    },
    tax(row) {
      let strTax = Math.floor(row.price * row.quantity * 0.1);
      return strTax;
    },
    total(row) {
      return this.sub_total(row) + this.tax(row);
    },
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "総計";
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          if (index === 2 || index === 6) {
            sums[index] = "";
          } else if (index === 3) {
            sums[index] = sums[index]
              .toString()
              .replace(/(\d)(?=(\d{3})+$)/g, "$1,");
            sums[index] += "点";
          } else if (index === 4) {
            sums[index] = sums[index]
              .toString()
              .replace(/(\d)(?=(\d{3})+$)/g, "$1,");
            sums[index] = "小計:  ¥" + sums[index];
          } else if (index === 5) {
            sums[index] = sums[index]
              .toString()
              .replace(/(\d)(?=(\d{3})+$)/g, "$1,");
            sums[index] = "消費税:  ¥" + sums[index] + "(10%)";
          } else {
            sums[index] = sums[index]
              .toString()
              .replace(/(\d)(?=(\d{3})+$)/g, "$1,");
            sums[index] += "円";
          }
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    postInvoiceDetailsData(row, index) {
      let values = {};
      if (this.price) {
        row.price = this.price;
      }
      values["index"] = index;
      values["invoice_code"] = this.cInvoiceCode;
      values["invoice_details_code"] = NewProjectcode();
      values["product_code"] = row.product_code;
      values["product_name"] = row.product_name;
      values["quantity"] = row.quantity;
      values["price"] = row.price;
      values["sub_total"] = this.sub_total(row).toString();
      values["tax"] = this.tax(row).toString();
      values["total"] = this.total(row).toString();
      values["remarks"] = row.remarks;
      values["main_flag"] = row.main_flag;
      values["modified_by"] = this.$store.getters.full_name;
      postInvoiceDetailsData(values)
        .then(res => {
          if (res.data.status === 1) {
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
            this.$emit("inviocedetdatareload");
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    postEstimateDetailsData(row, index) {
      let values = {};
      if (this.price) {
        row.price = this.price;
      }
      values["index"] = index;
      values["estimate_code"] = this.cEstimateCode;
      values["estimate_details_code"] = NewProjectcode();
      values["product_code"] = row.product_code;
      values["product_name"] = row.product_name;
      values["quantity"] = row.quantity;
      values["price"] = row.price;
      values["sub_total"] = this.sub_total(row).toString();
      values["tax"] = this.tax(row).toString();
      values["total"] = this.total(row).toString();
      values["remarks"] = row.remarks;
      values["main_flag"] = this.cMainFlag;
      values["created_by"] = this.$store.getters.full_name;
      postEstimateDetailsData(values)
        .then(res => {
          if (res.data.status === 1) {
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
            this.$emit("estimatedetdatareload");
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    putEstimateDetailsData(row, index) {
      let values = {};
      if (this.price) {
        row.price = this.price;
      }
      values["index"] = index;
      values["estimate_code"] = this.cEstimateCode;
      values["estimate_details_code"] = row.estimate_details_code;
      values["product_code"] = row.product_code;
      values["product_name"] = row.product_name;
      values["quantity"] = row.quantity;
      values["price"] = row.price;
      values["sub_total"] = this.sub_total(row).toString();
      values["tax"] = this.tax(row).toString();
      values["total"] = this.total(row).toString();
      values["remarks"] = row.remarks;
      values["main_flag"] = row.main_flag;
      values["modified_by"] = this.$store.getters.full_name;
      putEstimateDetailsData(values)
        .then(res => {
          if (res.data.status === 1) {
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
            this.$emit("estimatedetdatareload");
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    putInvoiceDetailsData(row, index) {
      let values = {};
      values["index"] = index;
      values["invoice_code"] = row.invoice_code;
      values["invoice_details_code"] = row.invoice_details_code;
      values["estimate_code"] = row.estimate_code;
      values["estimate_details_code"] = row.estimate_details_code;
      values["product_code"] = row.product_code;
      values["product_name"] = row.product_name;
      values["quantity"] = row.quantity;
      values["price"] = row.price;
      values["sub_total"] = this.sub_total(row).toString();
      values["tax"] = this.tax(row).toString();
      values["total"] = this.total(row).toString();
      values["remarks"] = row.remarks;
      values["main_flag"] = row.main_flag;
      values["modified_by"] = this.$store.getters.full_name;
      putInvoiceDetailsData(values)
        .then(res => {
          if (res.data.status === 1) {
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
            this.$emit("inviocedetdatareload");
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleDelete(index, row) {
      this.loading = true;
      this.editing = false;
      let values = {};
      values["invoice_details_code"] = row.invoice_details_code;
      values["estimate_details_code"] = row.estimate_details_code;
      if (row.invoice_details_code) {
        deleteInvoiceDetail(values)
          .then(res => {
            if (res.data.status === 1) {
              this.loading = false;
              Message({
                showClose: true,
                message: res.data.message,
                type: "success"
              });
              // 向父组件发送重新获取数据请求
              this.$emit("inviocedetdatareload");
            } else {
              Message({
                showClose: true,
                message: res.data.message,
                type: "error"
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        deleteEstimateDetail(values)
          .then(res => {
            if (res.data.status === 1) {
              this.loading = false;
              Message({
                showClose: true,
                message: res.data.message,
                type: "success"
              });
              // 向父组件发送重新获取数据请求
              this.$emit("estimatedetdatareload");
            } else {
              Message({
                showClose: true,
                message: res.data.message,
                type: "error"
              });
            }
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    stateFormat(row, column, cellValue) {
      cellValue += "";
      if (!cellValue.includes(".")) cellValue += ".";
      return cellValue
        .replace(/(\d)(?=(\d{3})+\.)/g, function($0, $1) {
          return $1 + ",";
        })
        .replace(/\.$/, "");
    },
    // 新增
    add() {
      this.price = "";
      this.cEstimateDetailsTableData.push({
        index: "",
        product_name: "",
        quantity: "",
        price: "",
        tax: "",
        total: "",
        remarks: "",
        isEdit: true,
        isAdd: true
      });
      this.editing = true;
    },
    // 编辑
    edit(row, index) {
      console.log(row, index);
      // 备份原始数据
      row["oldRow"] = JSON.parse(JSON.stringify(row));
      this.price = row.price;
      row.isEdit = true;
      this.editing = true;
    },
    //保存
    save(row, index) {
      if (row.isAdd) {
        if (this.cInvoiceCode) {
          this.postInvoiceDetailsData(row, index);
        } else {
          this.postEstimateDetailsData(row, index);
        }
      } else {
        if (this.cInvoiceCode) {
          this.putInvoiceDetailsData(row, index);
        } else {
          this.putEstimateDetailsData(row, index);
        }
      }
      row.isEdit = false;
      this.editing = false;
    },
    // 取消
    cancel(row, index) {
      // 如果是新增的数据
      if (row.isAdd) {
        // this.tableData.splice(index, 1);
        this.handleDelete(row, index);
      } else {
        // 不是新增的数据  还原数据
        for (const i in row.oldRow) {
          row[i] = row.oldRow[i];
        }
      }
      row.isEdit = false;
      this.editing = false;
    },
    querySearch(queryString, cb) {
      var cProductsDataAll = this.cProductsDataAll;
      var results = queryString
        ? cProductsDataAll.filter(this.createFilter(queryString))
        : cProductsDataAll;
      // 调用 callback 返回建议列表的数据

      // console.log(this.detailsForm.price)
      cb(results);
    },
    createFilter(queryString) {
      return restaurant => {
        // 从头开始匹配=== 0,全字段匹配> -1
        return restaurant.product_name
          .toLowerCase()
          .indexOf(queryString.toLowerCase()) > -1
          ? restaurant.product_name
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          : restaurant.category1
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          ? restaurant.category1
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1
          : restaurant.category2
              .toLowerCase()
              .indexOf(queryString.toLowerCase()) > -1;
      };
    },
    handleSelect(item) {
      // 将选择的商品信息回传给data
      this.price = item.price;
    },
    handleIconClick(ev) {
      console.log(ev);
    },
    //见积详细表颜色
    TableRowStyle({ row }) {
      let rowBackground = {};
      if (row.sub_total < 0) {
        rowBackground.background = "pink";
        return rowBackground;
      } else if (row.sub_total === 0) {
        rowBackground.background = "Ivory";
        return rowBackground;
      } else {
        rowBackground.background = "Ivory";
        return rowBackground;
      }
    }
  }
};
</script>

<style scoped>
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}

.category {
  font-size: 12px;
  color: #b4b4b4;
}

.highlighted .category {
  color: #ddd;
}
</style>
